import { CiSearch } from "react-icons/ci";
import { redirectTo } from "../utils/index";
import playasportLogo from '../assets/images/logo.png'
export const Header = () => {
  return (
    <header>
      <div className="flex flex-wrap justify-between w-full text-white px-10 text-center md:items-center border-b border-gray-200 py-5 gap-5">
        <div className="md:mx-0 mx-auto">
          <img
            src={playasportLogo}
            width="200px"
            alt=""
          />
        </div>
        <div className="flex flex-row items-center flex-wrap md:m-0 md:justify-none justify-center gap-5">
          <div
            className="flex items-center justify-center md:mr-10"
            onClick={() => {
              redirectTo("");
            }}
          >
            <div className="relative w-full max-w-md flex text-center">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                <CiSearch />
              </span>
              <input
                type="text"
                style={{ background: "#24473D" }}
                className="w-full py-2 pl-10 pr-4 rounded-xl"
                placeholder="Search"
              />
            </div>
          </div>

          <div className="flex flex-row items-center">
            <button
              className="px-3 py-2 rounded-xl font-semibold text-white mr-2 text-sm"
              style={{ background: "#0DB07D" }}
              onClick={() => {
                redirectTo("login");
              }}
            >
              Log in
            </button>
            <button
              className="px-3 py-2 rounded-xl font-semibold text-white mr-2 text-sm"
              style={{ background: "#24473D" }}
              onClick={() => {
                redirectTo("register");
              }}
            >
              Sign up
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};
