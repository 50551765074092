import { TiLocation } from "react-icons/ti";
import { FaPeopleLine } from "react-icons/fa6";
import { IoChatbubbleEllipsesSharp } from "react-icons/io5";
import { MdPayment } from "react-icons/md";

export const HowItWorks = () => {
  return (
    <div>
      <div className="font-bold text-3xl text-white my-10 text-center md:text-left">How it works</div>
      <div className="flex flex-row md:justify-between flex-wrap text justify-center">
        <div
          className="rounded-lg text-white border-[#336657] border p-3 leading-5 text-center flex flex-col items-center mb-5"
          style={{ background: "#1A332B" }}
        >
          <div className="mb-3">
            <TiLocation size={30} />
          </div>
          <div className="text-lg">Find local sport</div>
          <div className="text-[#336657]">
            Discover nearby sports activities
          </div>
        </div>

        <div
          className="rounded-lg text-white border-[#336657] border p-3 leading-5 text-center flex flex-col items-center mb-5"
          style={{ background: "#1A332B" }}
        >
          <div className="mb-3">
            <FaPeopleLine size={30} />
          </div>
          <div className="text-lg">Meet new friends</div>
          <div className="text-[#336657]">
            Connect with other sports enthusiasts
          </div>
        </div>

        <div
          className="rounded-lg text-white border-[#336657] border p-3 leading-5 text-center flex flex-col items-center mb-5"
          style={{ background: "#1A332B" }}
        >
          <div className="mb-3">
            <IoChatbubbleEllipsesSharp size={30} />
          </div>
          <div className="text-lg">Chat and connect</div>
          <div className="text-[#336657]">Communicate with other players</div>
        </div>

        <div
          className="rounded-lg text-white border-[#336657] border p-3 leading-5 text-center flex flex-col items-center mb-5"
          style={{ background: "#1A332B" }}
        >
          <div className="mb-3">
            <MdPayment size={30} />
          </div>
          <div className="text-lg">Pay with ease</div>
          <div className="text-[#336657]">Seamless payment management</div>
        </div>
      </div>
    </div>
  );
};
