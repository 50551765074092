import React from "react";

export const PrivacyPolicyPage = () => {
  return (
    <div className="py-10">
      <div className="container mx-auto">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
          <p className="mb-4">Last updated: 31/05/2024</p>

          <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
          <p className="mb-4">
            Welcome to Playasport. We are committed to protecting and respecting
            your privacy. This Privacy Policy explains how we collect, use, and
            disclose information about you when you use our website and
            services.
          </p>

          <h2 className="text-2xl font-semibold mb-2">
            2. Information We Collect
          </h2>
          <p className="mb-4">
            We may collect and process the following data about you:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>
              <strong>Personal Information:</strong> This includes information
              such as your name, email address, phone number, and other contact
              details you provide when you register on our platform.
            </li>
            <li>
              <strong>Event Information:</strong> Details of sports events you
              create, manage, or participate in, including schedules, locations,
              and team information.
            </li>
            <li>
              <strong>Usage Data:</strong> Information about how you use our
              platform, including your interactions with the services, IP
              address, browser type, and other usage details.
            </li>
            <li>
              <strong>Transaction Information:</strong> Details of transactions
              you carry out through our platform, including payment information.
            </li>
          </ul>

          <h2 className="text-2xl font-semibold mb-2">
            3. How We Use Your Information
          </h2>
          <p className="mb-4">
            We use your information for the following purposes:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>
              <strong>To Provide Our Services:</strong> To register you as a
              user, manage your profile, and facilitate your participation in
              sports events and teams.
            </li>
            <li>
              <strong>To Improve Our Services:</strong> To understand how you
              use our platform and to enhance and optimize our services.
            </li>
            <li>
              <strong>To Communicate with You:</strong> To send you
              notifications, updates, and other relevant communications about
              our services.
            </li>
            <li>
              <strong>To Process Transactions:</strong> To handle payments and
              other transactions securely.
            </li>
            <li>
              <strong>To Ensure Security:</strong> To protect our platform and
              users from fraud, unauthorized access, and other security issues.
            </li>
            <li>
              <strong>To Comply with Legal Obligations:</strong> To comply with
              applicable laws, regulations, and legal processes.
            </li>
          </ul>

          <h2 className="text-2xl font-semibold mb-2">
            4. How We Share Your Information
          </h2>
          <p className="mb-4">We may share your information with:</p>
          <ul className="list-disc list-inside mb-4">
            <li>
              <strong>Service Providers:</strong> Third-party service providers
              who help us operate our platform and provide our services.
            </li>
            <li>
              <strong>Legal and Regulatory Authorities:</strong> To comply with
              legal obligations or requests from government authorities.
            </li>
            <li>
              <strong>Business Transfers:</strong> In the event of a merger,
              acquisition, or sale of assets, your information may be
              transferred to the new entity.
            </li>
          </ul>

          <h2 className="text-2xl font-semibold mb-2">5. Your Rights</h2>
          <p className="mb-4">
            Under the UK GDPR, you have the following rights:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>
              <strong>Access:</strong> You have the right to request access to
              your personal information.
            </li>
            <li>
              <strong>Rectification:</strong> You have the right to request
              correction of any inaccurate or incomplete personal information.
            </li>
            <li>
              <strong>Erasure:</strong> You have the right to request deletion
              of your personal information.
            </li>
            <li>
              <strong>Restriction:</strong> You have the right to request
              restriction of processing your personal information.
            </li>
            <li>
              <strong>Portability:</strong> You have the right to request
              transfer of your personal information to another service provider.
            </li>
            <li>
              <strong>Objection:</strong> You have the right to object to the
              processing of your personal information.
            </li>
          </ul>
          <p className="mb-4">
            To exercise any of these rights, please contact us at [contact
            email].
          </p>

          <h2 className="text-2xl font-semibold mb-2">
            6. Cookies and Tracking Technologies
          </h2>
          <p className="mb-4">
            We use cookies and similar tracking technologies to enhance your
            experience on our platform.
          </p>

          <h2 className="text-2xl font-semibold mb-2">7. Data Security</h2>
          <p className="mb-4">
            We implement appropriate technical and organizational measures to
            protect your personal information from unauthorized access, loss, or
            misuse.
          </p>

          <h2 className="text-2xl font-semibold mb-2">8. Data Retention</h2>
          <p className="mb-4">
            We retain your personal information for as long as necessary to
            provide our services and fulfill the purposes outlined in this
            policy, unless a longer retention period is required or permitted by
            law.
          </p>

          <h2 className="text-2xl font-semibold mb-2">
            9. Changes to This Privacy Policy
          </h2>
          <p className="mb-4">
            We may update this Privacy Policy from time to time. We will notify
            you of any significant changes by posting the new policy on our
            platform and updating the "Last updated" date at the top of this
            page.
          </p>

          <h2 className="text-2xl font-semibold mb-2">10. Contact Us</h2>
          <p className="mb-4">
            If you have any questions or concerns about this Privacy Policy,
            please contact us at:
          </p>
          <p className="mb-4">
            PLayasport LTD
            <br />
            31 Richardson place
            <br />
            +44 7442796769
          </p>
        </div>
      </div>
    </div>
  );
};
