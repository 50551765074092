import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LayOut } from "./components/LayOut";
import { HomePage } from "./pages/HomePage";
import { PrivacyPolicyPage } from "./pages/PrivacyPolicyPage";
import { TermsAndConditionsPage } from "./pages/TermsAndConditionsPage";
function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <LayOut>
              <HomePage />
            </LayOut>
          }
        />
        <Route
          path="/privacy-policy"
          element={
            <LayOut>
              <PrivacyPolicyPage />
            </LayOut>
          }
        />
        <Route
          path="/terms-and-conditions"
          element={
            <LayOut>
              <TermsAndConditionsPage />
            </LayOut>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
