import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <>
      <div className="container py-5 mx-auto flex flex-row flex-wrap justify-around text-[#336657] border-3">
        <div>
          <Link
            to="/privacy-policy"
            className="hover:underline hover:text-white cursor"
          >
            Privacy Policy
          </Link>
        </div>
        <div>
          <Link
            to="/terms-and-conditions"
            className="cursor hover:underline hover:text-white "
          >
            Terms and Conditions
          </Link>
        </div>
      </div>
      <div className="text-[#336657] text-center py-2">
        {new Date().getFullYear()} &copy; playasport LTD - All rights reserved
      </div>
    </>
  );
};
