import { CiSearch } from "react-icons/ci";
import BannerImage from "../assets/images/badminton-banner.webp";
import { HowItWorks } from "../components/HowItWorks";
import { redirectTo } from "../utils";
export const HomePage = () => {
  return (
    <div className="py-10">
      {" "}
      {/* Add padding to account for the fixed header */}
      <div
        className="container mx-auto relative"
        style={{ minHeight: "calc(100vh - 400px)" }}
      >
        <div className="absolute inset-0 text-center">
          <img
            src={BannerImage}
            alt="placeholder"
            className="w-full h-full object-cover rounded-xl"
          />
          <div className="absolute inset-0 bg-black opacity-70 rounded-xl"></div>
        </div>
        <div className="relative flex flex-col h-full justify-end items-center text-center text-white z-10 md:py-20 p-10">
          <div className="">
            <h1 className="md:text-5xl font-bold text-4xl">
              Get active with playasport
            </h1>
            <p className="mt-4">
              Discover and join sports activities near you, or host your own
              game.
            </p>
          </div>
          <div
            className="mt-8 w-full max-w-md"
            onClick={() => {
              redirectTo("");
            }}
          >
            <div className="relative flex">
              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
                <CiSearch />
              </span>
              <input
                type="text"
                style={{ background: "#24473D" }}
                className="w-full py-2 pl-10 pr-4 rounded-xl rounded-r-none text-white"
                placeholder="Search"
              />
              <button className="bg-green-500 text-white rounded-xl px-4 py-2 rounded-l-none">
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto">
        <HowItWorks />
      </div>
    </div>
  );
};
