import { Header } from "./Header";
import { Footer } from "./Footer";
export const LayOut = ({ children }) => {
  return (
    <div style={{ background: "#12211C" }}>
      <Header />
      {children}
      <Footer />
    </div>
  );
};
